import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../../components/Layout';
import '../../styles/member-cards.css';

interface MemberProps {
	name: string;
	img: JSX.Element;
	position?: string;
	about: string;
	social: {
		li: string;
		mail?: string;
		rg?: string;
	};
}

const size = 36;

const Social = ({ li, mail, rg }: { mail?: string; li: string; rg?: string }) => (
	<div className='member__social'>
		<a href={`https://www.linkedin.com/in/${li}`} target='_blank'>
			<svg
				role='link'
				viewBox='0 0 24 24'
				width={size}
				height={size}
				xmlns='http://www.w3.org/2000/svg'
				fill='#0a66c2'
			>
				<title>LinkedIn</title>
				<path d='M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z' />
			</svg>
		</a>

		{mail && (
			<a href={`mailto:${mail}`} target='_blank'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width={size + 8}
					height={size + 10}
					viewBox='0 0 24 24'
					role='link'
					fill='#a0a0a0'
				>
					<path d='M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 4.7-8 5.334L4 8.7V6.297l8 5.333 8-5.333V8.7z'></path>
				</svg>
			</a>
		)}

		{rg && (
			<a href={`https://www.researchgate.net/profile/${rg}`} target='_blank'>
				<svg
					role='link'
					viewBox='0 0 24 24'
					width={size}
					height={size}
					xmlns='http://www.w3.org/2000/svg'
					fill='#00CCBB'
				>
					<title>ResearchGate</title>
					<path d='M19.586 0c-.818 0-1.508.19-2.073.565-.563.377-.97.936-1.213 1.68a3.193 3.193 0 0 0-.112.437 8.365 8.365 0 0 0-.078.53 9 9 0 0 0-.05.727c-.01.282-.013.621-.013 1.016a31.121 31.123 0 0 0 .014 1.017 9 9 0 0 0 .05.727 7.946 7.946 0 0 0 .077.53h-.005a3.334 3.334 0 0 0 .113.438c.245.743.65 1.303 1.214 1.68.565.376 1.256.564 2.075.564.8 0 1.536-.213 2.105-.603.57-.39.94-.916 1.175-1.65.076-.235.135-.558.177-.93a10.9 10.9 0 0 0 .043-1.207v-.82c0-.095-.047-.142-.14-.142h-3.064c-.094 0-.14.047-.14.141v.956c0 .094.046.14.14.14h1.666c.056 0 .084.03.084.086 0 .36 0 .62-.036.865-.038.244-.1.447-.147.606-.108.385-.348.664-.638.876-.29.212-.738.35-1.227.35-.545 0-.901-.15-1.21-.353-.306-.203-.517-.454-.67-.915a3.136 3.136 0 0 1-.147-.762 17.366 17.367 0 0 1-.034-.656c-.01-.26-.014-.572-.014-.939a26.401 26.403 0 0 1 .014-.938 15.821 15.822 0 0 1 .035-.656 3.19 3.19 0 0 1 .148-.76 1.89 1.89 0 0 1 .742-1.01c.344-.244.593-.352 1.137-.352.508 0 .815.096 1.144.303.33.207.528.492.764.925.047.094.111.118.198.07l1.044-.43c.075-.048.09-.115.042-.199a3.549 3.549 0 0 0-.466-.742 3 3 0 0 0-.679-.607 3.313 3.313 0 0 0-.903-.41A4.068 4.068 0 0 0 19.586 0zM8.217 5.836c-1.69 0-3.036.086-4.297.086-1.146 0-2.291 0-3.007-.029v.831l1.088.2c.744.144 1.174.488 1.174 2.264v11.288c0 1.777-.43 2.12-1.174 2.263l-1.088.2v.832c.773-.029 2.12-.086 3.465-.086 1.29 0 2.951.057 3.667.086v-.831l-1.49-.2c-.773-.115-1.174-.487-1.174-2.264v-4.784c.688.057 1.29.057 2.206.057 1.748 3.123 3.41 5.472 4.355 6.56.86 1.032 2.177 1.691 3.839 1.691.487 0 1.003-.086 1.318-.23v-.744c-1.031 0-2.063-.716-2.808-1.518-1.26-1.376-2.95-3.582-4.355-6.074 2.32-.545 4.04-2.722 4.04-4.9 0-3.208-2.492-4.698-5.758-4.698zm-.515 1.29c2.406 0 3.839 1.26 3.839 3.552 0 2.263-1.547 3.782-4.097 3.782-.974 0-1.404-.03-2.063-.086v-7.19c.66-.059 1.547-.059 2.32-.059z' />
				</svg>
			</a>
		)}
	</div>
);

const Member = ({ about, img, name, social: { li, mail, rg }, position }: MemberProps) => (
	<div className='br member px-3'>
		{img}
		<div className='member__body'>
			<h2 className='member__title raleway-700'>{name}</h2>
			{position && <span className='member__subtitle text-secondary'>{position}</span>}
			<div className='member__about'>{about}</div>
			<Social li={li} mail={mail} rg={rg} />
		</div>
	</div>
);

const AboutTeamPage = () => (
	<Layout title='About the Team'>
		<h1 className='raleway-700 display-5 my-3 px-3'>The Student Board</h1>
		<div className='row mb-5 justify-content-evenly align-items-start'>
			<Member
				name='Chirayu Patel'
				position='President'
				about={`Chirayu is a PhD candidate in the Molecular Medicine and Translational Sciences Program at Wake
                                                Forest School of Medicine. He has contributed to spaceflight research in numerous ways,
						most recently as part of the JPG-RR MHU8 mission to the ISS in collaboration with JAXA and NASA
                                                which investigated artificial gravity as a countermeasure to spaceflight-induced osteoarthritis. 
						Previously, he took part in the Rodent Research 18 mission to the ISS (2022)
                                                which investigated an antioxidant countermeasure to protect astronauts during long duration spaceflight.
						In addition to his research experiments in space, Chirayu has also completed ground-based combined hazard studies
                                                to assess biological alterations as a result of the spaceflight environment at the NASA space radiation lab.
						His research focuses on the investigation of novel countermeasures to prevent joint degeneration during long duration spaceflight.`}
				img={
					<StaticImage
						src='../../images/team/chirayu.jpg'
						alt='Chirayu Patel'
						class='member__image'
						aspectRatio={1}
						formats={['auto', 'webp']}
						placeholder='blurred'
					/>
				}
				social={{
					li: 'chirayu-patel',
					mail: 'cpatel@wakehealth.edu'

				}}
			/>

			<Member
				name='Annie Shelton'
				position='President-Elect'
				about={`Annie is a Graduate Student at Cornell University pursuing a Master's in Integrative Plant Science with a focus in Controlled Environment Agriculture.
                                              She holds a B.S. in Neuroscience with a minor in Plant Biology from the University of California Riverside.
					      Annie's research projects cover a range of topics including Hydroponic Tree Production, Artificial Photosynthesis,
	                                      Heterotrophic and Hydroponic Fungal Cultivation, Heterotrophic and Hydroponic In Vitro Tomato Callus Production, Engineering Plants for Space,
				              CEA Optimization, Planetary Habitability Metrics, and Software Development. After completing her Master's this Spring, Annie plans to pursue a Ph.D. connected to Space Agriculture.
	                                      With her interdisciplinary background, Annie plans to contribute to Human Space Exploration in her future career.` }
				img={
					<StaticImage
						src='../../images/team/Annie.png'
						alt='Annie Shelton'
						class='member__image'
						aspectRatio={1}
						formats={['auto', 'webp']}
						placeholder='blurred'
					/>
				}
				social={{ li: 'annie-shelton' }}
			/>

			<Member
				name='Anna Wadhwa'
				position='Education & Outreach Coordinator'
				about={`Anna is a medical student and PhD candidate at the Harvard Medical School/MIT MD-PhD program.
                                             She previously obtained a B.S. in Biochemistry and minors in Math and Philosophy with honors from the New Jersey Institute of Technology.
					     She was involved in the international NASA/JAXA MHU-8 mission to the International Space Station in 2023 to explore the use of artificial gravity
	                                     as a countermeasure for microgravity-associated skeletal losses and bone marrow single-cell transcriptomic changes.
				             Anna is also currently exploring the use of lower-body negative pressure suits to mitigate the fluid shifts that occur in microgravity.
		                             Ultimately, she is interested in enabling human space exploration to the Moon, Mars, and beyond. 
.`}
				img={
					<StaticImage
						src='../../images/team/Wadhwa.jpg'
						alt='Anna Wadhwa'
						class='member__image'
						aspectRatio={1}
						formats={['auto', 'webp']}
						placeholder='blurred'
					/>
				}
				social={{ li: 'annawadhwa' }}
			/>

			<Member
				name='Ana Paula Guevara-Cerdán'
				position='Diversity, Equity, and Inclusion Chair'
				about={`Ana Paula is a PhD candidate in Molecular and Cellular Biology at the University of Sheffield in the United Kingdom.
                                                  She holds a BSc in Genetics and Molecular Cell Biology, and an MSc in Antimicrobial Resistance,
						  also from the University of Sheffield. Ana Paula’s PhD project focuses on the roles of phosphoinositide 3-kinase (PI3K) isoforms
	                                         in the regulation of phagocytosis and macropinocytosis in Dictyostelium discoideum. She has begun studying these processes in simulated microgravity
					         after building a low-budget clinostat as per Herrera-Jordan et al., 2021, flying payload on a CubeSat in collaboration with engineering undergraduates at her university,
	                                        and is now sponsored by Cell Spinpod LLC to continue carrying out microgravity research alongside her PhD. After completing her thesis in 2025,
					       Ana Paula hopes to join the NASA postdoctoral program (NPP). As a Mexican national living abroad, working in academia as a female researcher, and living with two disabilities,
	                                       Ana Paula is passionate about ED&I in all areas of Human Space Exploration`}
				img={
					<StaticImage
						src='../../images/team/Ana.png'
						alt='Ana Paula Guevara-Cerdán'
						class='member__image'
						aspectRatio={1}
						formats={['auto', 'webp']}
						placeholder='blurred'
					/>
				}
				social={{ li:' anapaulaguevaracerdan ' }}
			/>

			<Member
				name='Anna Suvorova'
				position='Social Media Coordinator'
				about={`Anna Suvorova is a high school student interning in the High School Machine Learning and Space Biology Volunteer Program.
                                                      Her research focuses on the intersection of Machine Learning and plant classification, exploring the effects of spaceflight and ground control
						      by analyzing plants’ RNA. Presently, Anna is involved in rodent research and contributes to a global-scale meta-analysis.
	                                              Moreover, she collaborates with a professor in investigating astronauts' movements in space and compares them with movement
					              on the ground to draw conclusions about conditions in zero gravity. Her future plans include majoring in engineering and attaining a Ph.D. in the field.`}
				img={
					<StaticImage
						src='../../images/team/Anna.S.jpeg'
						alt='Anna Suvorova'
						class='member__image'
						aspectRatio={1}
						formats={['auto', 'webp']}
						placeholder='blurred'
					/>
				}
				social={{ li: '/anna-suvorova-b09466268' }}
			/>

			<Member
				name='Kristina Mikhailova'
				position='Secretary and Treasurer'
				about={`Kristina is a Graduate Student at Cornell University pursuing a Master's degree in Integrative Plant Science with a focus in Controlled Environment Agriculture.
                                                 She holds a Bachelor’s degree in Physics and Mathematics with a minor in PACS and honors in Business and Management from DePauw University.
						 Kristina's research projects cover a range of topics related to CEA Optimization. After completing her Master's this Spring,
                                                 Kristina plans to pursue a Ph.D. in Systems Engineering, developing mathematical optimization models for Bioregenerative Life Support Systems
						 in space and CEA systems on Earth. In the long term, Kristina wants to contribute to Mars colonization, but also create sustainable environments on Earth
                                                 for a fast growing population.`}
				img={
					<StaticImage
						src='../../images/team/Kristina.jpg'
						alt='Kristina Mikhailova'
						class='member__image'
						aspectRatio={1}
						formats={['auto', 'webp']}
						placeholder='blurred'
					/>
				}
				social={{ li: 'kristinamikhailova' }}
			/>

			<Member
				name='Nick Miklave'
				position='Event Coordinator'
				about={`Nick is a Ph.D Candidate for Environmental and Evolutionary Biology at the University of Louisiana at Lafayette, and has a B.S. in Physics and Astronomy from Stony Brook University.
                                             His interests include the mechanisms that plants use to interact with their physical environment and how these evolved mechanisms can be integrated with new technologies
					     to overcome the challenges of growing crops during spaceflight. He is a NASA Space Technology Graduate Research Fellow, developing synthetic substrates that can be used
	                                     as a tool for general plant biology research and as a lightweight and multipurpose substrate for crop production in space environments.`}
				img={
					<StaticImage
						src='../../images/team/Nick.png'
						alt='Nick Miklave'
						class='member__image'
						aspectRatio={1}
						formats={['auto', 'webp']}
						placeholder='blurred'
					/>
				}
				social={{ li: 'nmiklave' }}
			/>

			<Member
				name='Julianna Rose'
				position='SECA Coordinator'
				about={`Julianna is an undergraduate at Cornell University studying Plant Science with a focus on controlled environment agriculture and plant adaptations to the space environment.
                                                 She is passionate about sustainable agriculture, particularly hydroponics, and its applications to long duration space missions and habitation.
						 Julianna is currently engaged in research regarding both microgravity and hypergravity effects on plants, and intends to continue astrobotany research
                                                 with a Ph.D. program and beyond.`}
				img={
					<StaticImage
						src='../../images/team/Juliana.jpg'
						alt='Julianna Rose'
						class='member__image'
						aspectRatio={1}
						formats={['auto', 'webp']}
						placeholder='blurred'
					/>
				}
				social={{ li: '' }}
			/>
		</div>
		<h2 className='raleway-700 display-6 my-3 px-3'>Social Media Team</h2>
		<div className='row justify-content-evenly align-items-start mb-5'>
			<Member
				name='Ruth Singh'
				about={`Ruth Singh is an incoming PhD student in the Blaber lab at RPI. She has completed
						her Bachelor’s in Physics and a Diploma in Astronomy and Astrophysics from University of Mumbai, India.
						She is also a Board of Trustee at ‘Project Encephalon’ a neuroscience organization in India. She is
						currently working as a Research Intern at Mason Lab analysing single cell omics datasets from spaceflight missions.`}
				img={
					<StaticImage
						src='../../images/team/ruth.jpg'
						alt='Ruth Singh'
						class='member__image'
						aspectRatio={1}
						formats={['auto', 'webp']}
						placeholder='blurred'
					/>
				}
				social={{ li: 'ruth-singh08', mail: 'singhr9@rpi.edu' }}
			/>

			{/* <Member
				name='Mesgana Admassu'
				about={`Mesgana Admassu is a recent graduate of Howard University, having obtained a B.S. in Biology. While
						pursuing her degree, she conducted research in her university’s Department of Biology. The project
						encompassed comparing the adverse effects sustained on red maple trees from climate change. In 2021,
						she interned at NASA Ames Research Center to study wildfire plume dispersion in Northern California
						and at NASA Kennedy Space Center to study the effects of microgravity on differential gene
						expression. Mesgana is honored to be working with the ASGSR Student Board to continue exposing
						students to space studies!`}
				img={
					<StaticImage
						src='../../images/team/mesgana.jpeg'
						alt='Mesgana Admassu'
						class='member__image'
						aspectRatio={1}
						formats={['auto', 'webp']}
						placeholder='blurred'
					/>
				}
				social={{ li: 'mesganaadmassu', mail: 'mesadmassu@gmail.com' }}
			/> */}
		</div>
	</Layout>
);

export default AboutTeamPage;
